import { Controller } from "@hotwired/stimulus"

function getSelectedOptionFromSelect(selectElement) {
  const { selectedIndex } = selectElement
  return selectElement.options[selectedIndex]
}

function getSelectedValueFromSelect(selectElement) {
  const selectedOption = getSelectedOptionFromSelect(selectElement)
  const { value } = selectedOption

  return value
}

export default class extends Controller {
  static targets = [ "loserSelect", "winnerSelect" ]

  loserChanged({ target }) {
    const value = getSelectedValueFromSelect(target)
    this.#disableWinnerOptionWithValue(value)
  }

  winnerChanged({ target }) {
    const value = getSelectedValueFromSelect(target)
    this.#disableLoserOptionWithValue(value)
  }

  #disableLoserOptionWithValue(someValue) {
    const options = this.loserSelectTarget.options

    this.#disableOptionWithValue({
      options,
      value: someValue
    })
  }

  #disableWinnerOptionWithValue(someValue) {
    const options = this.winnerSelectTarget.options

    this.#disableOptionWithValue({
      options,
      value: someValue
    })
  }

  #disableOptionWithValue({ options, value: someValue }) {
    Array.from(options).forEach((option) => {
      const valueMatches = someValue !== "" && option.value == someValue

      if (valueMatches && option.selected) {
        option.selected = false
      }

      option.disabled = valueMatches
    })
  }
}
